import { intervalToDuration } from "date-fns";
import type { DistanceUnit } from "./distance_units";
import { METER_TO_KILOMETER_SCALAR, METER_TO_MILE_SCALAR, METER_TO_YARD_SCALAR } from "./dto/pace/PaceData";

export function displayable_duration(start_timestamp: Date, stop_timestamp: Date, compressed: boolean = false) {

    let duration = intervalToDuration({start: start_timestamp, end: stop_timestamp});
    let to_return = "";

    if (!compressed) {

        // if (!pad) {
            
            if (duration.hours !== undefined) {
                to_return += duration.hours + "h ";
            }

            if (duration.minutes !== undefined || to_return.length > 0) {
                to_return += (duration.minutes ?? 0) + "m ";
            }

            if (duration.seconds !== undefined || to_return.length > 0) {
                to_return += (duration.seconds ?? 0) + "s";
            }

        // } else {

            // if (duration.hours !== undefined) {
            //     to_return += duration.hours.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + "h ";
            // } else {
            //     to_return += "0h ";
            // }
    
            // if (duration.minutes !== undefined) {
            //     to_return += duration.minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + "m ";
            // } else {
            //     to_return += "0m ";
            // }
    
            // if (duration.seconds !== undefined) {
            //     to_return += duration.seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + "s";
            // } else {
            //     to_return += "0s";
            // }
    
        // }

    } else {//if (compressed) {

        if (duration.hours !== undefined) {
            to_return += duration.hours.toLocaleString('en-US', {minimumIntegerDigits: 1, useGrouping: false}) + ":";
        } else {
            to_return += "0:";
        } 

        if (duration.minutes !== undefined) {
            to_return += duration.minutes.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) + ":";
        } else {
            to_return += "00:";
        }

        if (duration.seconds !== undefined) {
            to_return += duration.seconds.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});
        } else {
            to_return += "00";
        }    
    }

    return to_return.trim();
}

export function scroll_component_into_view(component_id: string) {

    let component = document.getElementById(component_id);
    component?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
    // component?.scrollIntoView(true);
};

export function displayable_distance(distance_in_meters: number, 
                                     distance_unit: DistanceUnit,
                                     decimals_to_show: number = 2) {

    let distance_string;
    if (distance_unit === 'm') {
        distance_string = `${Math.round(distance_in_meters).toLocaleString()}`;
    } else if (distance_unit === 'yds') {
        distance_string = `${Math.round((distance_in_meters * METER_TO_YARD_SCALAR)).toLocaleString()}`;
    } else if (distance_unit === 'km') {
        distance_string = `${(distance_in_meters * METER_TO_KILOMETER_SCALAR).toFixed(decimals_to_show)}`;
    } else if (distance_unit === 'mi') {
        distance_string = `${(distance_in_meters * METER_TO_MILE_SCALAR).toFixed(decimals_to_show)}`;
    } else {
        distance_string = `${(distance_in_meters).toLocaleString()}`;
    }

    return distance_string;
}
